import React from 'react'

export default function Hero() {
  return (
    <main>
      <div className="relative bg-marianaBlue">
        <div className="px-4 py-16 mx-auto max-w-[1500px] md:px-24 lg:px-8 lg:py-60">
          <div className="relative max-w-2xl sm:mx-auto sm:max-w-xl md:max-w-2xl sm:text-center">
            <h2 className="mb-6 font-sans text-3xl font-bold tracking-tight text-white sm:text-4xl sm:leading-none">
              Contact Us
            </h2>
          </div>
        </div>
      </div>
    </main>
  )
}
