import React from 'react'
import { Header } from '../../Components'

export default function Blog() {
  return (
    <main>
      <Header />
    </main>
  )
}
